import React from 'react'


const PreviewFooter = () => {
    return (
        <footer className="previewFooterArea">
            <div className="container">
                <p> 2019 All Right Reserved</p>
            </div>
        </footer>
    )
};
export default PreviewFooter