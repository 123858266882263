import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PreviewSectionTitle from './SectionTitle'

// images 
import home from '../images/demo/home.jpg';
import about from '../images/demo/about-us.jpg';
import blog from '../images/demo/blog.jpg';
import blogDetails from '../images/demo/blog-details.jpg';
import contact from '../images/demo/contact-us.jpg';
import gallery from '../images/demo/gallery.jpg';
import portfolio from '../images/demo/portfolio.jpg';
import service from '../images/demo/services.jpg';
import portfolioSingle from '../images/demo/portfolio-single.jpg';
import team from '../images/demo/team.jpg';
import login from '../images/demo/login.jpg';
import signup from '../images/demo/signup.jpg';

// backoffice
import b_about from '../images/demo/backoffice-screen/about.png';
import b_blog from '../images/demo/backoffice-screen/blog.png';
import b_contact from '../images/demo/backoffice-screen/contact.png';
import b_dashboard from '../images/demo/backoffice-screen/dashboard.png';
import b_gallery from '../images/demo/backoffice-screen/gallery.png';
import b_generalSetting from '../images/demo/backoffice-screen/general-setting.png';
import b_login from '../images/demo/backoffice-screen/login.png';
import b_plan from '../images/demo/backoffice-screen/plan.png';
import b_portfolio from '../images/demo/backoffice-screen/portfolio.png';
import b_profile from '../images/demo/backoffice-screen/profile.png';
import b_service from '../images/demo/backoffice-screen/service.png';
import b_team from '../images/demo/backoffice-screen/team.png';
import b_testimonial from '../images/demo/backoffice-screen/testimonial.png';
import b_user from '../images/demo/backoffice-screen/user.png';
import b_webSetting from '../images/demo/backoffice-screen/web-setting.png';

const demos = [
    {name: 'Home Page', image: home, link: 'home'},
    {name: 'About Us', image: about, link: 'about-us'},
    {name: 'Service', image: service, link: 'services'},
    {name: 'Blog', image: blog, link: 'blog'},
    {name: 'Blog Details', image: blogDetails, link: 'blog-details/1'},
    {name: 'Portfolio', image: portfolio, link: 'portfolio'},
    {name: 'Portfolio Single', image: portfolioSingle, link: 'portfolio-single'},
    {name: 'Contact us', image: contact, link: 'contact-us'},
    {name: 'Gallery', image: gallery, link: 'gallery'},
    {name: 'Our Team', image: team, link: 'team'},
];
const demos2 = [
    {name: 'login', image: b_login, link: 'signin'},
    {name: 'dashboard', image: b_dashboard, link: ''},
    {name: 'blog', image: b_blog, link: 'blog-list'},
    {name: 'gallery', image: b_gallery, link: 'gallery-list'},
    {name: 'plan', image: b_plan, link: 'plan-list'},
    {name: 'portfolio', image: b_portfolio, link: 'portfolio-list'},
    {name: 'service', image: b_service, link: 'service-list'},
    {name: 'team', image: b_team, link: 'team-list'},
    {name: 'testimonial', image: b_testimonial, link: 'testimonial-list'},
    {name: 'user', image: b_user, link: 'user-list'},
    {name: 'profile', image: b_profile, link: 'profile'},
    {name: 'general setting', image: b_generalSetting, link: 'general-setting'},
    {name: 'web-setting', image: b_webSetting, link: 'web-setting'},
    {name: 'about', image: b_about, link: 'about-us'},
    {name: 'contact', image: b_contact, link: 'contact-list'},
];


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PreviewDemo = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Fragment>
            <div className="previewDemoArea" id="demo">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <PreviewSectionTitle
                                title="Demo"
                                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
                            />
                        </div>
                        <Tabs className="demoTabMenu" centered value={value} onChange={handleChange}
                              aria-label="simple tabs example">
                            <Tab disableRipple label="FrontEnd" {...a11yProps(0)} />
                            <Tab disableRipple label="Back Office" {...a11yProps(1)} />
                        </Tabs>
                        <div className="col-12">
                            <TabPanel value={value} index={0}>
                                <div className="row">
                                    {demos.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <Link className="demoInfo" target="_blank"
                                                      to={`/${demo.link}`}>{demo.name}</Link>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt=""/>
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                        <div className="col-12">
                            <TabPanel value={value} index={1}>
                                <div className="row">
                                    {demos2.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <a className="demoInfo" target="_blank"
                                                      href={`https://ammelias-laravel.itech-theme.com/${demo.link}`}>{demo.name}</a>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt=""/>
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PreviewDemo