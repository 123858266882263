import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import rtl from './rtl.jpg';

import './style.scss';

const VersionSwitcher = () => {
    return (
        <Grid className="verionSwitcher">
            <Button component={Link} href="https://ammelias-rtl.itech-theme.com/">
                <p>RTL</p>
                <img src={rtl} alt="rtl"/>
            </Button>
        </Grid>
    )
};

export default VersionSwitcher;